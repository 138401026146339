<template>
  <div class="settings">
  <h1>Seadistus</h1>
  <v-card flat>
    <v-tabs>
      <v-tab>
        <v-icon left>
          mdi-key-wireless
        </v-icon>
        API võtmed
      </v-tab>
      <v-tab>
        <v-icon left>
          mdi-map-marker-circle
        </v-icon>
        Jälgimisleht
      </v-tab>
      <v-tab>
        <v-icon left>
          mdi-cached
        </v-icon>
        Majandustarvara
      </v-tab>
      <v-tab>
        <v-icon left>
          mdi-email
        </v-icon>
        Email
      </v-tab>
      <v-tab-item>
        <Api :config="this.config" v-on:update_config="updateConfig"></Api>
      </v-tab-item>
      <v-tab-item>
        <v-card flat>
          <v-card-text>
          </v-card-text>
        </v-card>
      </v-tab-item>
      <v-tab-item>
        <v-card flat>
          <v-card-text>
          </v-card-text>
        </v-card>
      </v-tab-item>
      <v-tab-item>
          <Email></Email>
      </v-tab-item>
    </v-tabs>
  </v-card>
  </div>
</template>
<script>
import Email from "../components/settings/Email";
import Account from "../components/settings/Account";
import Api from "../components/settings/Api";
import Carriers from "../components/settings/Carriers";
const axios = require('axios');
export default {
  name: 'Shipments',
  components: {Carriers, Api, Account, Email},
  data: () => ({
    config: []
  }),
  mounted () {
    let self = this;
    axios.get(axios.defaults.baseURL + '/configurations').then((response) => {
      this.config = response.data;
    }).catch(function (error) {
      if (error.response) {
        if (error.response.status === 401) {
          self.$router.push('/')
        }
      }
    });
  },
  methods: {
    updateConfig: function (config) {
      //console.log(config);
      //this.config = config;
    }
  }
}
</script>
