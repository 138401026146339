<template>
  <h2>Venipak</h2>
</template>

<script>
export default {
  name: "Venipak"
}
</script>

<style scoped>

</style>