<template>
  <div class="container">
    <v-btn
        tile
        dark
        color="#48a95a"
        depressed
        @click="saveDesign()"
    >
      Salvesta disain
    </v-btn>
    <EmailEditor ref="emailEditor" v-on:load="editorLoaded" />
  </div>
</template>

<script>
import {EmailEditor} from "vue-email-editor";

export default {
  name: "Email",
  components: {
    EmailEditor,
  },
  data: () => ({
    emailDesign: {
      "counters": {
        "u_column": 1,
        "u_row": 1,
        "u_content_heading": 1,
        "u_content_button": 1,
        "u_content_text": 1,
        "u_content_image": 1
      },
      "body": {
        "rows": [
          {
            "cells": [
              1
            ],
            "columns": [
              {
                "contents": [
                  {
                    "type": "image",
                    "values": {
                      "containerPadding": "24px 10px",
                      "src": {
                        "url": "https://unroll-images-production.s3.amazonaws.com/projects/0/1633171852732-noks-sm.png",
                        "width": 200,
                        "height": 54,
                        "autoWidth": false,
                        "maxWidth": "24%"
                      },
                      "textAlign": "center",
                      "altText": "",
                      "action": {
                        "name": "web",
                        "values": {
                          "href": "",
                          "target": "_blank"
                        }
                      },
                      "_meta": {
                        "htmlID": "u_content_image_1",
                        "htmlClassNames": "u_content_image"
                      },
                      "selectable": true,
                      "draggable": true,
                      "duplicatable": true,
                      "deletable": true,
                      "hideable": true
                    }
                  },
                  {
                    "type": "heading",
                    "values": {
                      "containerPadding": "10px",
                      "headingType": "h1",
                      "fontFamily": {
                        "label": "Arial",
                        "value": "arial,helvetica,sans-serif"
                      },
                      "fontSize": "22px",
                      "textAlign": "center",
                      "lineHeight": "140%",
                      "linkStyle": {
                        "inherit": true,
                        "linkColor": "#0000ee",
                        "linkHoverColor": "#0000ee",
                        "linkUnderline": true,
                        "linkHoverUnderline": true
                      },
                      "_meta": {
                        "htmlID": "u_content_heading_1",
                        "htmlClassNames": "u_content_heading"
                      },
                      "selectable": true,
                      "draggable": true,
                      "duplicatable": true,
                      "deletable": true,
                      "hideable": true,
                      "text": "Teie {{shop_name}} tellimus on saadetud ja teel sihtkohta!"
                    }
                  },
                  {
                    "type": "button",
                    "values": {
                      "containerPadding": "10px",
                      "href": {
                        "name": "web",
                        "values": {
                          "href": "",
                          "target": "_blank"
                        }
                      },
                      "buttonColors": {
                        "color": "#FFFFFF",
                        "backgroundColor": "#0f475d",
                        "hoverColor": "#FFFFFF",
                        "hoverBackgroundColor": "#3AAEE0"
                      },
                      "size": {
                        "autoWidth": true,
                        "width": "100%"
                      },
                      "textAlign": "center",
                      "lineHeight": "120%",
                      "padding": "10px 20px",
                      "border": {},
                      "borderRadius": "20px",
                      "_meta": {
                        "htmlID": "u_content_button_1",
                        "htmlClassNames": "u_content_button"
                      },
                      "selectable": true,
                      "draggable": true,
                      "duplicatable": true,
                      "deletable": true,
                      "hideable": true,
                      "text": "J&auml;lgi saadetist",
                      "calculatedWidth": 128,
                      "calculatedHeight": 36
                    }
                  },
                  {
                    "type": "text",
                    "values": {
                      "containerPadding": "10px",
                      "textAlign": "left",
                      "lineHeight": "140%",
                      "linkStyle": {
                        "inherit": true,
                        "linkColor": "#0000ee",
                        "linkHoverColor": "#0000ee",
                        "linkUnderline": true,
                        "linkHoverUnderline": true
                      },
                      "_meta": {
                        "htmlID": "u_content_text_1",
                        "htmlClassNames": "u_content_text"
                      },
                      "selectable": true,
                      "draggable": true,
                      "duplicatable": true,
                      "deletable": true,
                      "hideable": true,
                      "text": "<p style=\"font-size: 14px; line-height: 140%;\">Meil on hea meel jagada Teile j&auml;lgimiskoodi, mis v&otilde;imaldab Teil vaadata oma saadetise liikumist ja asukohta reaalajas.</p>\n<p style=\"font-size: 14px; line-height: 140%;\">&nbsp;</p>\n<p style=\"font-size: 14px; line-height: 140%;\">Kui Teil on k&uuml;simusi oma tellimuse kohta, siis vastake sellele kirjale v&otilde;i helistage meile.</p>"
                    }
                  }
                ],
                "values": {
                  "backgroundColor": "",
                  "padding": "0px",
                  "border": {},
                  "_meta": {
                    "htmlID": "u_column_1",
                    "htmlClassNames": "u_column"
                  }
                }
              }
            ],
            "values": {
              "displayCondition": null,
              "columns": false,
              "backgroundColor": "",
              "columnsBackgroundColor": "",
              "backgroundImage": {
                "url": "",
                "fullWidth": true,
                "repeat": false,
                "center": true,
                "cover": false
              },
              "padding": "0px",
              "hideDesktop": false,
              "_meta": {
                "htmlID": "u_row_1",
                "htmlClassNames": "u_row"
              },
              "selectable": true,
              "draggable": true,
              "duplicatable": true,
              "deletable": true,
              "hideable": true
            }
          }
        ],
        "values": {
          "textColor": "#000000",
          "backgroundColor": "#ffffff",
          "backgroundImage": {
            "url": "",
            "fullWidth": true,
            "repeat": false,
            "center": true,
            "cover": false
          },
          "contentWidth": "500px",
          "contentAlign": "center",
          "fontFamily": {
            "label": "Arial",
            "value": "arial,helvetica,sans-serif"
          },
          "preheaderText": "",
          "linkStyle": {
            "body": true,
            "linkColor": "#0000ee",
            "linkHoverColor": "#0000ee",
            "linkUnderline": true,
            "linkHoverUnderline": true
          },
          "_meta": {
            "htmlID": "u_body",
            "htmlClassNames": "u_body"
          }
        }
      },
      "schemaVersion": 6
    }
  }),
  methods: {
    editorLoaded() {
      // Pass the template JSON here
      this.$refs.emailEditor.editor.loadDesign(this.emailDesign);
    },
    saveDesign() {
      this.$refs.emailEditor.editor.saveDesign((design) => {
        console.log(design);
      });
    },
    exportHtml() {
      this.$refs.emailEditor.editor.exportHtml((data) => {
        console.log('exportHtml', data);
      });
    },
  },
}
</script>

<style scoped>

</style>