<template>
  <v-container>
    <v-row>
      <h2>Omniva</h2>
      <v-switch
          v-model="this.config.omniva"
          color="indigo"
          hide-details
      ></v-switch>
    </v-row>
    <v-row>
      <v-text-field label="Kasutajanimi" v-model="this.config.omniva_username" @keyup="updateConfig"></v-text-field>
    </v-row>
    <v-row>
      <v-text-field label="Parool" v-model="this.config.omniva_password"></v-text-field>
    </v-row>

  </v-container>
</template>

<script>
export default {
  name: "Omniva",
  props: ['config'],
  methods: {
    updateConfig: function () {
      this.$emit("update_config", this.config)
    }
  }
}
</script>

<style scoped>

</style>