<template>
  <v-card flat class="carriers">
    <v-tabs vertical>
      <v-tab>
        Smartpost
      </v-tab>
      <v-tab>
        Omniva
      </v-tab>
      <v-tab>
        DPD
      </v-tab>
      <v-tab>
        Venipak
      </v-tab>
      <v-tab-item>
        <Smartpost
            :config="this.config"
            v-on:update_config="updateConfig">
        </Smartpost>
      </v-tab-item>
      <v-tab-item>
        <Omniva
            :config="this.config"
            v-on:update_config="updateConfig">
        </Omniva>
      </v-tab-item>
      <v-tab-item>
        <DPD
            :config="this.config"
            v-on:update_config="updateConfig"
        ></DPD>
      </v-tab-item>
      <v-tab-item>
        <Venipak></Venipak>
      </v-tab-item>
    </v-tabs>
  </v-card>
</template>

<script>
import Smartpost from "./carriers/Smartpost";
import DPD from "./carriers/DPD";
import Venipak from "./carriers/Venipak";
import Omniva from "./carriers/Omniva";
export default {
  name: "Carriers",
  props: ['config'],
  components: {Omniva, Venipak, DPD, Smartpost},
  methods: {
    updateConfig: function (config) {
      console.log(config);
      this.config = config;
      //this.$emit("update_config", this.config)
      //console.log(config.smartpost_username);
    }
  }
}
</script>

<style scoped>

</style>