<template>
  <v-container>
    <v-row>
    <h2>Smartpost</h2>
    <v-switch
        v-model="this.config.smartpost"
        color="indigo"
        hide-details
    ></v-switch>
    </v-row>
    <v-row>
      <v-text-field label="Kasutajanimi" v-model="this.config.smartpost_username" @keyup="updateConfig"></v-text-field>
    </v-row>
    <v-row>
      <v-text-field label="Parool" v-model="this.config.smartpost_password"></v-text-field>
    </v-row>

  </v-container>
</template>

<script>
export default {
  name: "Smartpost",
  props: ['config'],
  methods: {
    updateConfig: function () {
      this.config.smartpost_password = 'test';
      this.$emit("update_config", this.config)
    }
  }
}
</script>

<style scoped>

</style>