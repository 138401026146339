<template>
  <div>
    <v-text-field label="Main input"></v-text-field>
    <v-text-field label="Another input"></v-text-field>
  </div>
</template>

<script>
export default {
  name: "Account"
}
</script>

<style scoped>

</style>