<template>
  <div>
    <v-text-field
        label="API avalik võti"
        v-model="this.config.api_key"
        @click="copyText(config.api_key)"
    ></v-text-field>
    <v-text-field
        label="API salajane võti"
        v-model="this.config.api_secret"
        @click="copyText(config.api_secret)"
        :readonly=true
    ></v-text-field>
    <v-snackbar
        v-model="snackbar"
        :timeout="timeout"
        color="#6aa775"
    >
      Kopeeritud
    </v-snackbar>
  </div>
</template>

<script>
export default {
  name: "Api",
  props: ['config'],
  data: () => ({
    snackbar: false,
    timeout: 1500,
  }),
  methods: {
    copyText(text) {
      let value = text;
      navigator.clipboard.writeText(value);
      this.snackbar = true;
    },
    editRecipient: function () {
      console.log('edit');
    }
  }
}
</script>

<style scoped>

</style>